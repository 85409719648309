import {FC, useEffect, useState} from 'react';
import AxiosInterceptor from './AxiosInterceptor';
import Header from './Header';
import {IonRouterOutlet} from '@ionic/react';
import {Redirect, Route} from 'react-router-dom';
import {Routes} from '../shared/routes';
import Home from '../pages/Home';
import About from '../pages/About';
import DownloadsPage from '../pages/Downloads';
import News from '../pages/News';
import Support from '../pages/Support';
// import Login from '../pages/Login_v2';
// import Login from '../pages/Login';
import Login from '../pages/Login_v3';
import Signup from '../pages/SignUp';
import NotificationsPage from '../pages/Notifications';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Terms from '../pages/Terms';
import HowToDeleteYourData from '../pages/HowToDeleteYourData';
import MyProfile from '../pages/MyProfile/indexNew';
import ChannelBroadcast from '../pages/ChannelBroadcast';
import Article from '../pages/Article';
import Career from '../pages/Career';
import SharedStreamPage from '../pages/SharedStream';
import SharedStreamTvPage from '../pages/SharedStream/tvStream';
import Footer from './Footer';
import {IonReactRouter} from '@ionic/react-router';
import appStorage, {StorageKey} from '../shared/appStorage';
import {useDispatch, useSelector} from 'react-redux';
import {Profile, ReduxSelectors} from '../redux/shared/types';
import {setLogin} from '../redux/actions/profileActions';
import RoomPage from '../pages/Room';
import {RouteComponentProps} from 'react-router';
import DownloadApp, {showDownloadApp} from './DownloadApp';
import StreamTestPage from '../pages/StreamTest';
import LocationState from '../models/LocationState';
import {BillingServices, UserManagementService} from '../services';
import ResetPasswordPage from '../pages/ResetPassword';
import BaseService from '../services/BaseService';
import WatchPartyJoinMediator from '../pages/WatchParty/Join/WatchPartyJoinMediator';
import {setInfoToast} from '../redux/actions/toastActions';
import WatchPartyJoinHome from '../pages/WatchParty/Join/JoinHome';
import WatchPartyJoin from '../pages/WatchParty/Join';
import WatchPartyStart from '../pages/WatchParty/Start';
import ChannelsPage from '../pages/Channels';
import FavoritesPage from '../pages/Favorites';
import RoomsPage from '../pages/Rooms';
import SearchPage from '../pages/Search';
import GenrePage from '../pages/Genre';
import { setBillingTimeEvents, setDailyVisitReward, setEnableRewardPopup, setTotalStarBalance } from '../redux/actions/billingRewardActions';
import Premium from '../pages/Premium';
import Stars from '../pages/Stars';
// import InviteAndWin from '../pages/Stars/InviteAndWin';
// import Shop from '../pages/Stars/Shop';
// import StarsStatusTable from '../pages/Stars/StarsStatusTable';
// import TopUp from '../pages/Stars/TopUp';
// // import CashOut from '../pages/Stars/CashOut';
import PremiumViewers from '../pages/Premium/PremiumViewers';
import PremiumHosts from '../pages/Premium/PremiumHosts';
import AccountStatus from '../pages/AccountStatus';
import InviteAndWin from '../pages/Stars/InviteAndWin';
import Shop from '../pages/Stars/Shop';
import StarsStatusTable from '../pages/Stars/StarsStatusTable';
import StarsTransaction from '../pages/Stars/StarsTransaction';
// import TopUp from '../pages/Stars/TopUp';
// import TopupAndCashout from '../pages/Stars/TopupAndCashout';

const Router: FC = () => {
  const dispatch = useDispatch();
  const pathName = window?.location?.pathname?.split('/')
  const [isTvStreamPage,setIsTvStreamPage] = useState(false)
  useEffect(()=> {

    if(pathName.length > 1 && pathName[1] === 'tvStream'){
      setIsTvStreamPage(true)
    }
  },[pathName])

  const {jwt, hasConfirmedPhoneNumber, isAnonymous} = useSelector(({profile}: ReduxSelectors) => profile);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const storageData = appStorage.getObject(StorageKey.Login);
    if (storageData) {

      // billing:
      const currClientDate = new Date().toJSON();
      const eventType = "site.opened"

      BaseService.setJwt(storageData.jwt);
      UserManagementService.getUserData()
        .then(({data: {result}}) => {

          const profileData: Profile = {
            jwt: storageData.jwt,
            id: result.id,
            email: result.email.includes('@skiplogin.com') ? "" : result.email,
            nickname: result.nickname || result.username,
            firstName: result.first_name,
            lastName: result.last_name,
            preferredLanguage: result.preferred_language,
            preferredGenre: result.preferred_genre,
            isOverEighteen: result.has_confirmed_is_over_eighteen || false,
            hasConfirmedPhoneNumber: result.has_confirmed_phone_number || false,
            phoneNumber: result.phone_number,
            showDebugInfo: result.show_debug_info || false,
            avatar: result.avatar,
            isAnonymous: result.email.includes('@skiplogin.com') ? true : false
          };

          dispatch(setLogin(profileData));

          // billing:
          if(result && !profileData.isAnonymous) {
            BillingServices.billingEvent(currClientDate, profileData.id, eventType).then(({data: {result}}) => {
              dispatch(setDailyVisitReward(result))
              if(result.billingReward.creditedStars) {
                dispatch(setEnableRewardPopup({dailyVisitReward: true}))
              }
            })

            BillingServices.billingStarBalance(profileData.id).then(({data}) => {
              console.log("result", data)
              if(data)(
                dispatch(setTotalStarBalance(data))
              )
              return;
            })

            BillingServices.getBillingEvents().then(({data: {result}}) => {
              console.log("events", result)
              dispatch(setBillingTimeEvents(result))
            })
          }
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [dispatch]);

  const handleStreamIdRender = (props: RouteComponentProps) => {
    const splittedPath = props.location.pathname.split("/")

    if (!isAnonymous && (props.location.pathname === Routes.Login)) {
      return;
    }

    if(props.history.action === "POP" && splittedPath[1] === "stream") {
      return <Redirect
        to={{pathname: Routes.Home}}
       />
    }

    if (jwt) {
      return showDownloadApp(props.location, Routes.Stream) ?
        <DownloadApp {...props}/> :
        <SharedStreamPage {...props}/>;
    } else {
      return (
        <Redirect
          to={{
            pathname: Routes.Login,
            state: new LocationState({redirectTo: props.location.pathname})
          }}
        />
      );
    }
  };

  const handleStreamIdRoomRender = (props: RouteComponentProps) => (
    showDownloadApp(props.location, Routes.Stream) ?
      <DownloadApp {...props}/> :
      <SharedStreamPage {...props}/>
  );

  const handleStreamIdTvRender = (props: RouteComponentProps) => {
    if (props.location.pathname === Routes.Login) {
      return <SharedStreamTvPage {...props}/>;
    } else {
      return (
        <Redirect
          to={{
            pathname: Routes.Login,
            state: new LocationState({redirectTo: props.location.pathname})
          }}
        />
      );
    }
  };

  const handleStreamIdRoomTvRender = (props: RouteComponentProps) => (
      <SharedStreamTvPage {...props}/>
  );


  const showInfoToast = (message: string) => {
    // delay message show
    setTimeout(() => {
      dispatch(setInfoToast(message));
    }, 500);
  };

  const handleJoinIdRender = (props: RouteComponentProps) => (
    showDownloadApp(props.location, Routes.WatchParty) ?
      <DownloadApp {...props}/> :
      <WatchPartyJoinHome {...props}/>
  );

  const handleJoinRender = (props: RouteComponentProps) => (
    showDownloadApp(props.location, Routes.WatchParty) ?
      <DownloadApp {...props}/> :
      <WatchPartyJoin {...props}/>
  );

  const handleWatchPartyStartRender = (props: RouteComponentProps) => {
    if (!jwt) {
      showInfoToast('login.loginFirst');

      return (
        <Redirect
          exact
          from={Routes.WatchPartyStart}
          to={{
            pathname: Routes.Login,
            state: new LocationState({redirectTo: Routes.WatchPartyStart})
          }}
        />
      );
    }

    if (!hasConfirmedPhoneNumber) {
      showInfoToast('notifications.confirmNumberFirst');

      return <Redirect
        exact
        from={Routes.WatchPartyStart}
        to={Routes.MyProfile}
      />;
    }

    return <WatchPartyStart {...props}/>;
  };

  if (loading) {
    return null;
  }


  return (
    <IonReactRouter>
      {!isTvStreamPage ?<AxiosInterceptor/>:null}

      {!isTvStreamPage ? <Header/> : null}

      <IonRouterOutlet>
        <Route exact path={Routes.Home} component={Home}/>
        <Route exact path={Routes.About} component={About}/>
        <Route exact path={Routes.Downloads} component={DownloadsPage}/>
        <Route exact path={Routes.News} component={News}/>
        {/* billing: */}
        <Route
          exact
          path={Routes.Stars}
          render={
            props => jwt && !isAnonymous ?
              <Stars /> :
              <Redirect exact from={Routes.Stars} to={Routes.Login} />
          }
        />
        <Route
          exact
          path={Routes.Premium}
          render={
            props => jwt && !isAnonymous ?
              <Premium /> :
              <Redirect exact from={Routes.Premium} to={Routes.Login} />
          }
        />
        <Route
          exact
          path={Routes.PremiumViewer}
          render={
            props => jwt && !isAnonymous ?
              <PremiumViewers /> :
              <Redirect exact from={Routes.PremiumViewer} to={Routes.Login} />
          }
        />
        <Route
          exact
          path={Routes.PremiumHost}
          render={
            props => jwt && !isAnonymous ?
              <PremiumHosts /> :
              <Redirect exact from={Routes.PremiumHost} to={Routes.Login} />
          }
        />
        <Route exact path={Routes.Support} component={Support} />
        <Route
          exact
          path={Routes.InviteAndWin}
          render={
            props => jwt && !isAnonymous ?
              <InviteAndWin /> :
              <Redirect exact from={Routes.InviteAndWin} to={Routes.Login} />
          }
        />
        <Route
          exact
          path={Routes.Shop}
          render={
            props => jwt && !isAnonymous ?
              <Shop /> :
              <Redirect exact from={Routes.Shop} to={Routes.Login} />
          }
        />
        <Route
          exact
          path={Routes.StarsStatusTable}
          render={
            props => jwt && !isAnonymous ?
              <StarsStatusTable /> :
              <Redirect exact from={Routes.StarsStatusTable} to={Routes.Login} />
          }
        />
        <Route
          exact
          path={Routes.StarsTransaction}
          render={
            props => jwt && !isAnonymous ?
              <StarsTransaction /> :
              <Redirect exact from={Routes.StarsTransaction} to={Routes.Login} />
          }
        />
        {/* <Route
          exact
          path={Routes.TopUp}
          render={
            props => jwt && !isAnonymous ?
              <TopUp /> :
              <Redirect exact from={Routes.TopUp} to={Routes.Login} />
          }
        /> */}
      
        <Route exact path={Routes.WatchPartyRoomId} render={handleJoinIdRender}/>
        <Route exact path={Routes.WatchPartyRoomIdMediator} component={WatchPartyJoinMediator}/>
        <Route path={Routes.WatchPartyJoin} render={handleJoinRender}/>
        <Route path={Routes.WatchPartyStart} render={handleWatchPartyStartRender}/>
        <Redirect exact from={Routes.WatchParty} to={Routes.WatchPartyJoin}/>

        <Route exact path={Routes.Login} component={Login}/>
        <Route exact path={Routes.SignUp} component={Signup}/>
        <Route exact path={Routes.Notifications} component={NotificationsPage}/>
        <Route exact path={Routes.PrivacyPolicy} component={PrivacyPolicy}/>
        <Route exact path={Routes.TermsAndConditions} component={Terms}/>
        <Route exact path={Routes.HowToDeleteYourData} component={HowToDeleteYourData}/>
        <Route
          exact
          path={Routes.MyProfile}
          render={
            props => jwt && !isAnonymous ?
              <MyProfile {...props}/> :
              <Redirect exact from={Routes.MyProfile} to={Routes.Login}/>
          }
        />
        {/* billing: */}
        <Route
          exact
          path={Routes.AccountStatus}
          render={
            props => jwt && !isAnonymous ?
              <AccountStatus {...props}/> :
              <Redirect exact from={Routes.AccountStatus} to={Routes.Login}/>
          }
        />
        <Route exact path={Routes.SharedSites} component={ChannelBroadcast}/>
        <Route exact path={Routes.NewsId} component={Article}/>
        <Route exact path={Routes.Career} component={Career}/>
        <Route exact path={Routes.StreamId} render={handleStreamIdRender}/>
        <Route exact path={Routes.StreamIdRoom} render={handleStreamIdRoomRender}/>

        <Route exact path={Routes.StreamIdTv} render={handleStreamIdTvRender}/>
        <Route exact path={Routes.StreamIdRoomTv} render={handleStreamIdRoomTvRender}/>

        <Route path={Routes.Room} component={RoomPage}/>
        <Route
          exact
          path={Routes.StreamTest}
          render={
            props => jwt ?
              <StreamTestPage {...props}/> :
              <Redirect exact from={Routes.StreamTest} to={Routes.Login}/>
          }
        />
        <Route path={Routes.ResetPassword} component={ResetPasswordPage}/>

        <Route path={Routes.Favorites} component={FavoritesPage}/>
        <Route path={Routes.Rooms} component={RoomsPage}/>
        <Route path={Routes.Channels} component={ChannelsPage}/>
        <Route path={Routes.Search} component={SearchPage}/>
        <Route path={Routes.Genre} component={GenrePage} />

        <Redirect exact from="/" to={Routes.Home}/>
      </IonRouterOutlet>

      {!isTvStreamPage ? <Footer/> : null}
    </IonReactRouter>
  );
};

export default Router;
